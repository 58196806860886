import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        projects: [],
        sel_project: {},
        templates: [],
        deleted_projects: [],
        inherited_project: {}
    },
    mutations: {
        set_projects_mutations(state, payload) {
            state.projects = [...payload];
        },
        set_single_project_mutations(state, payload) {
            state.projects = [payload];
        },
        set_project_template(state, payload) {
            state.templates = [...payload];
        },
        add_new_project_mutations(state, payload) {
            state.projects.push(payload);
            state.sel_project = payload;
        },
        add_project_section_mutations(state, payload) {           
            const project = state.projects.find(project => project.id == payload.project_id);
            project.sections.push(payload.data);
            state.sel_project = project;
        },
        remove_project_section_mutations(state, payload) {           
            const project_index = state.projects.findIndex(project => project.id === payload.project_id);
            state.projects[project_index].sections.splice(state.projects[project_index].sections.
                findIndex(section => section.id === payload.section_id), 1);
                
            const searched_section = state.sel_project.sections.find(section => section.id === payload.section_id);
            if ( typeof searched_section !== 'undefined' ) {
                state.sel_project.sections.splice(state.sel_project.sections.findIndex(section => section.id === payload.section_id), 1);
            }
        },
        set_selected_project_mutations(state, payload) {
            state.sel_project = payload;
        },
        update_edited_project_mutation(state, payload) {           
            const search_project_index = state.projects.findIndex(project => project.id === payload.id);
            state.projects[search_project_index] = payload;
        },
        delete_project_mutations(state, payload) {
            /*const search_project_index = state.projects.findIndex(project => project.id === payload.id);
            state.projects.splice(search_project_index, 1);
            state.sel_project = state.projects[0];*/
            const searched_project = state.projects.find(project => project.id === payload.id);
            const remaining_projects = state.projects.filter( project => project.name != searched_project.name );            
            state.projects = remaining_projects;
            state.sel_project = state.projects[0];
        },
        /** Task CRUD Mutations */
        add_new_task_mutation(state, payload) {                 
            const project = state.projects.find(project => project.id == payload.section.project_id);
            const section = project.sections.find(section => section.id == payload.section.id);
            const sel_section = state.sel_project.sections.find(section => section.id == payload.section.id);

            payload.data.duration = '';
            payload.data.start = new Date().toLocaleDateString();
            payload.data.finish = new Date().toLocaleDateString();
            payload.data.who = '';
            payload.data.notes = '';
            payload.data.status = 'Pending';

            section.get_sub_tasks.push(payload.data);         
            if ( sel_section.get_sub_tasks.findIndex(task => task.id == payload.data.id) == -1 ) {
                sel_section.get_sub_tasks.push(payload.data);
            }
        },
        update_task_mutation(state, payload) {
            /** Update Projects */     
            const project = state.projects.find(project => project.id == payload.section.project_id);
            const section = project.sections.find(section => section.id == payload.section.id);
            const task = section.get_sub_tasks.find(task => task.id == payload.data.id);
            task.name = payload.data.name;
            task.duration = payload.data.duration;
            task.status = payload.data.status;
            task.start = payload.data.start;
            task.finish = payload.data.finish;
            task.notes = payload.data.notes;
            task.who = payload.data.who;
            
            /** Update Selected Project */
            const sel_section = state.sel_project.sections.find( (section) => section.id == payload.section.id)
            const sel_task = sel_section.get_sub_tasks.find( (task) => task.id == payload.data.id)

            sel_task.name = payload.data.name;
            sel_task.duration = payload.data.duration;
            sel_task.status = payload.data.status;
            sel_task.start = payload.data.start;
            sel_task.finish = payload.data.finish;
            sel_task.notes = payload.data.notes;
        },
        delete_task_mutation(state, payload) {
            const project = state.projects.find(project => project.id == state.sel_project.id);
            const section = project.sections.find(section => section.id == payload.project_task_id);            
            section.get_sub_tasks.splice(section.get_sub_tasks.findIndex(task => task.id == payload.id), 1);

            const sel_section = state.sel_project.sections.find(section => section.id == payload.project_task_id);
            sel_section.get_sub_tasks.splice(sel_section.get_sub_tasks.findIndex(task => task.id == payload.id), 1);
        },
        update_checklist_item_mutation(state, payload) {
            const project = state.projects.find(project => project.id == payload.project_id);
            const checklist = project.checklist.find(item => item.id == payload.parent_id);
            const sub_checklist = checklist.get_child_tasks.find(item => item.id == payload.id);
            if ( typeof sub_checklist !== 'undefined' ) {
                sub_checklist.is_done = payload.is_done;
                sub_checklist.notes = payload.notes;
            }
        },
        set_deleted_projects(state, payload) {
            state.deleted_projects = [...payload];
        },
        set_inherited_projects_mutation(state, payload) {
            state.inherited_project = payload;
        },
        update_section_orders_mutation(state, payload) {            
            const project = state.projects.find(project => project.id == payload.project_id);
            project.sections = payload.sections;
            state.sel_project.sections = payload.sections;
        }
    },
    getters: {
        get_projects_getter: state => state.projects,
        get_selected_project_getter: state => state.sel_project,
        get_project_template_getter: state => state.templates,
        get_deleted_projects_getter: state => state.deleted_projects,
        get_inherited_project: state => state.inherited_project
    },
    actions: {
        async fetch_projects_api_actions({commit}, payload){           
            await Vue.axios.get('/projectplanner/get_projects')
            .then( ({data}) => {                          
                if ( payload == true ) {    
                    commit('set_projects_mutations', data.data);
                } else {         
                    if ( data.data.length > 0 ) {                             
                        commit('set_single_project_mutations', data.data[0]);
                    }
                }
            });
        },       
        async add_project_section_actions({commit}, payload) {           
            await Vue.axios.post(`/projectplanner/add_project_section/${payload.project_id}`, payload.data)
            .then( ({data}) => {
                commit('add_project_section_mutations', {project_id: payload.project_id, data: data.data[0]});
            });
            //commit('add_project_section_mutations', payload);
        },
        async delete_project_section_actions({commit}, payload) {            
            await Vue.axios.delete(`/projectplanner/delete_project_section/${payload.section_id}`)
            .then( ({data}) => {
                commit('remove_project_section_mutations', {project_id: payload.project_id, section_id: data.data[0].id});
            });
        },
        set_selected_project_actions({commit}, payload) {           
            commit('set_selected_project_mutations', payload);
        },
        fetch_project_templates_actions({commit}) {
            //await Vue.axios.get('/projectplanner/get_template')
            Vue.axios.get('/projectplanner/get_project_templates')
            .then( ({data}) => {
                //console.log(data);
                commit('set_project_template', data.data);
            });
        },
        /** Add, Edit, Delete Project */
        async add_project_actions(context, payload) {           
            await Vue.axios.post('/projectplanner/add_project', payload)
            .then( ({data}) => {
                context.commit('add_new_project_mutations', data.data);
                context.commit('auth/set_newly_added_project_mutations', data.data, {root: true});

                /** Process module inherit from deleted project */
                const old_projectid = context.getters.get_inherited_project.old_projectid;
                if ( old_projectid != null ) {
                    const new_projectid = data.data.id;
                    Vue.axios.post(`/projectplanner/execute_module_inheritance`, {
                        old_projectid: old_projectid,
                        new_projectid: new_projectid
                    }).then( ({data}) => {
                        console.log(data.data);
                    });
                }
            });
        },
        async edit_project_actions({commit}, payload) {           
            await Vue.axios.patch(`/projectplanner/update_project/${payload.project_id}`, {
                'name': payload.name
            }).then( ({data}) => {               
                commit('set_selected_project_mutations', data.data);
                commit('update_edited_project_mutation', data.data);

                // Update the data under auth               
                commit('auth/update_edited_project_mutations', data.data, {root: true});
            })
        },
        async delete_project_actions({commit}, payload) {           
            await Vue.axios.delete(`/projectplanner/delete_project/${payload.project_id}`)
            .then( ({data}) => {
                if ( data.response == true ) {                   
                    commit('delete_project_mutations', {id: payload.project_id});
                    commit('auth/delete_selected_project_mutations', {id: payload.project_id}, {root: true});
                } else {
                    console.log(data.reponse.data);
                }
            });
        },
        /** Task CRUD */
        async add_new_task_actions({commit}, payload) {           
            await Vue.axios.post(`/projectplanner/add_section_task/${payload.section.id}`, payload.data)
            .then( ({data}) => {
                if ( data.response == true ) {
                    commit('add_new_task_mutation', {section: payload.section, data: data.data[0]});
                } else {
                    console.log(data.data);
                }
            });
        },
        async edit_task_actions({commit}, payload) {
            await Vue.axios.patch(`/projectplanner/edit_section_task/${payload.task_id}`, payload.data)
            .then( ({data}) => {
                commit('update_task_mutation', {section: payload.section, data: data.data[0]});
                Vue.$toast.success('Successfully updated the project task.')
            }).catch( function(err) {
                Vue.$toast.error(err.message)
            });
        },
        async delete_task_actions({commit}, payload) {            
            await Vue.axios.delete(`/projectplanner/delete_section_task/${payload.task_id}`)
            .then( ({data}) => {
                if ( data.response == true ) {                    
                    commit('delete_task_mutation', data.data[0]);
                } else {
                    console.log(data.data);
                }
            });
        },
        /** Checklist edit */
        async edit_checklist_subitem_actions({commit}, payload) {            
            await Vue.axios.patch(`/projectplanner/edit_checklist/${payload.checklist_id}`, payload.data)
            .then( ({data}) => {
                if ( data.response == true ) {
                    commit('update_checklist_item_mutation', data.data);
                } else {
                    console.log(data.data);
                }
            });
        },
        /** Deleted Projects */
        async fetch_deleted_projects_actions({commit}, payload) {
            await Vue.axios.get(`/projectplanner/get_deleted_projects/${payload.user_id}`)
                .then( ({data}) => {
                    commit('set_deleted_projects', data.data);
                });
        },
        set_inherited_projects_action({commit}, payload) {
            commit('set_inherited_projects_mutation', payload);
        },
        async update_reorder_of_sections_action({commit}, payload) {
            await Vue.axios.patch(`/projectplanner/reorder_sections`, payload.data)
            .then( ({data}) => {
                if ( data.response == true ) {
                    commit(`update_section_orders_mutation`, payload);
                }
            });            
        }
    }
}